<template>
  <Layout>
    <div class="InfoCompanySetting">
      <page-header title="会社情報" :items="item" />
      <div class="InfoCompanySetting__content">
        <setting-info-form />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PageHeader from '../../layouts/vertical/page-header'
import SettingInfoForm from './setting-info-form'
export default {
  name: 'SettingInfoCompany',
  components: { SettingInfoForm, PageHeader, Layout },
  data() {
    return {
      item: [
        {
          text: '設定',
          href: '#',
        },
        {
          text: '会社情報',
          href: '#',
        },
      ],
    }
  },
}
</script>
